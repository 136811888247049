// TV2DKHEADER
(() => {
  const header = document.querySelector('.tc_header');
  const headerHeight = header.offsetHeight;
  const initAnimationAfter = 500;
  const scrollTriggerDelta = 100;

  let scrolling;
  let ticking = false;
  let lastScroll = 0;

  // HEADER VISIBILITY
  const headerAnimate = () => {
    // scrolling down
    if (scrolling - scrollTriggerDelta > lastScroll) {
      if (headerHeight < scrolling) {
        header.classList.remove('tc_header--sticky-show');
      }

      if (headerHeight * 2 < scrolling) {
        header.classList.add('tc_header--sticky');
      }

      if (initAnimationAfter < scrolling) {
        // we need to make sure tc_header--sticky and the animate class
        // is not set in the same tick
        // if they are both added in the same tick, the animation transition will flash
        setTimeout(() => {
          header.classList.add('tc_header--sticky-animate');
        }, 0);
      }

      lastScroll = scrolling;

      // scrolling up
    } else if (
      scrolling + scrollTriggerDelta < lastScroll ||
      scrolling < headerHeight
    ) {
      if (!header.classList.contains('tc_header--sticky-animate')) {
        header.classList.remove('tc_header--sticky');
      }

      header.classList.add('tc_header--sticky-show');

      lastScroll = scrolling;
    }

    if (
      scrolling === 0 ||
      (header.getAttribute('data-variant') === 'feature' &&
        scrolling < headerHeight)
    ) {
      header.classList.remove('tc_header--sticky');
      header.classList.remove('tc_header--sticky-animate');
      header.classList.remove('tc_header--sticky-show');
    }

    ticking = false;
  };

  const headerRequestTick = () => {
    if (!ticking) {
      ticking = true;
      requestAnimationFrame(headerAnimate);
    }
  };

  const headerOnScroll = () => {
    scrolling = window.scrollY;
    headerRequestTick();
  };

  window.addEventListener('scroll', headerOnScroll, false);

  // MENU DIALOG
  const menuToggleButton = header.querySelector(
    '.tc_header__global__buttons__menu',
  );
  const menuDialog = header.querySelector(
    '.tc_header__global__buttons__dialog',
  );
  const menuDialogCloseButton = header.querySelector(
    '.tc_header__dialog__header__close',
  );
  const menuDialogCmpButton = header.querySelector('[data-cmp="cpc-open"]');

  // tracking
  const trackEvent = (event) => {
    try {
      window.tv2.analyticsUtils.trackEvent(event);
    } catch (_) {
      console.warn('unable to track event', { event });
    }
  };

  if (menuToggleButton && menuToggleButton.dataset.processed !== 'js') {
    const openMenuDialog = () => {
      // Handle scrollbars (I dream of a cross-browser CSS approach to this, but none exist currently)
      const root = document.documentElement;
      const scrollbarWidth = window.innerWidth - root.clientWidth;
      root.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);

      if (typeof HTMLDialogElement === 'function') {
        menuDialog.showModal();
      }

      // Timeout of '0' makes Safari animate dialog properly in :(
      setTimeout(() => {
        menuToggleButton.setAttribute('aria-expanded', 'true');
      }, 0);

      menuDialogCloseButton.addEventListener('click', closeMenuDialogOnClick);
      menuDialog.addEventListener('cancel', closeMenuDialogOnClick);
      menuDialog.addEventListener('click', closeMenuDialogOnClickOutside);
      menuDialogCmpButton.addEventListener('click', closeMenuDialogOnClick);

      // Timeout removes unwanted focus-visible on 'close button' in desktop Safari :(
      setTimeout(() => {
        menuDialogCloseButton.blur();
      }, 100);

      animateAccordion();

      trackEvent('tv2dk.menu.open');
    };

    const closeMenuDialog = () => {
      menuToggleButton.setAttribute('aria-expanded', 'false');
      menuDialogCloseButton.removeEventListener('click', closeMenuDialog);
      menuDialog.removeEventListener('cancel', closeMenuDialogOnClick);
      menuDialog.removeEventListener('click', closeMenuDialogOnClickOutside);
      menuDialogCmpButton.removeEventListener('click', closeMenuDialog);

      // Wait for CSS animation to end before closing dialog
      setTimeout(() => {
        menuDialog.close();
      }, 300);

      trackEvent('tv2dk.menu.close');
    };

    const closeMenuDialogOnClick = () => {
      closeMenuDialog();
    };

    const closeMenuDialogOnClickOutside = (event) => {
      if (event.target === menuDialog) {
        closeMenuDialog();
      }
    };

    menuToggleButton.addEventListener('click', openMenuDialog);
    menuToggleButton.setAttribute('data-processed', 'js');
  }

  // ACCORDION ANIMATION
  const animateAccordion = () => {
    const menuDetailsWrapper = header.querySelector(
      '.tc_header__dialog__main__nav',
    );
    const menuDetailsList = menuDetailsWrapper.querySelectorAll('details');

    if (menuDetailsWrapper.dataset.processed !== 'js') {
      menuDetailsList.forEach((details) => {
        const summary = details.querySelector('summary');
        const link = summary.querySelector('a');

        details.style.maxHeight = `${details.offsetHeight}px`;

        summary.addEventListener('click', (e) => {
          e.target === summary && e.preventDefault();
          const summaryHeight = summary.offsetHeight;

          if (e.target !== link) {
            if (details.hasAttribute('open')) {
              details.style.maxHeight = `${summaryHeight}px`;
              setTimeout(() => details.removeAttribute('open'), 300);
            } else {
              details.setAttribute('open', 'open');
              const ulHeight = details.querySelector('ul').offsetHeight;
              const detailsHeight = `${summaryHeight + ulHeight}px`;
              details.style.maxHeight = detailsHeight;
            }
          }
        });
      });
      menuDetailsWrapper.setAttribute('data-processed', 'js');
    }
  };

  // REEL REFERER
  const referrer = document.referrer;
  const referrerUrl = referrer && referrer !== '' && new URL(document.referrer);
  const referrerFromTv2Dk =
    referrerUrl && referrerUrl.hostname.indexOf('tv2.dk') > -1;

  // if the user came from tv2.dk give them a 'close button' instead of the burger menu
  if (referrerFromTv2Dk) {
    const button = document.querySelector('.tc_header__global__buttons__close');
    if (button && history.length > 1) {
      button.removeAttribute('hidden');
      button.addEventListener('click', () => history.back());
    }
  }

  // LOGIN BUTTON
  const loginButton = document.querySelector(
    '.tc_header__global__buttons__login',
  );
  loginButton.addEventListener('click', async (event) => {
    event.preventDefault();
    const isAuthenticated = await window.tv2.auth0.client.isAuthenticated();
    if (!isAuthenticated) {
      window.tv2.auth0.login();
    } else {
      window.location.href = 'https://mit.tv2.dk';
    }
  });

  // THEME
  const themeMeta = document.querySelector('meta[name="color-scheme"]');
  const themeMetaValue = themeMeta.getAttribute('content');
  const themeForm = document.querySelector('.tc_header__dialog__main__theme');
  const themeRadioButtons = themeForm.querySelectorAll('input[name="theme"]');
  const themeDefaultValue = 'light dark';
  const themeCookieValue = window.tv2.utils.getCookie('tv2Theme');

  // set theme in metatag (CSR) and store theme selection in a cookie (SSR)
  const setTheme = (themeValue) => {
    if (themeValue !== 'auto') {
      themeMeta.setAttribute('content', themeValue);
      window.tv2.utils.setCookie('tv2Theme', themeValue, '365 days');
    } else {
      themeMeta.setAttribute('content', themeDefaultValue);
      window.tv2.utils.setCookie('tv2Theme', '', -1);
    }
  };

  if (themeCookieValue) {
    // change selected radio option based on cookie
    themeForm.querySelector(
      `input[value="${themeCookieValue}"]`,
    ).checked = true;

    // keep cookie and metatag in sync
    if (themeCookieValue !== themeMetaValue) {
      themeMeta.setAttribute('content', themeCookieValue);
    }
  } else {
    // force default setting if no cookie exists
    if (themeMetaValue !== themeDefaultValue) {
      themeMeta.setAttribute('content', themeDefaultValue);
    }
  }

  // listen for theme selection
  for (const themeRadioButton of themeRadioButtons) {
    themeRadioButton.addEventListener('change', (e) => {
      setTheme(e.target.value);
      trackEvent(`tv2dk.theme.${e.target.value}`);
    });
  }
})();
